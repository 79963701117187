import React from 'react';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../Navbar';
import { useBrowserDevice } from '@/common';

export const BlankLayout: React.FC<unknown> = (): JSX.Element => {
  const { isMobile } = useBrowserDevice();

  return (
    <div className="w-full flex flex-col bg-white sm:bg-black/[.02]">
      {!isMobile && <NavBar toggleSideBar={() => undefined} />}
      <div className="container m-auto">
        <Outlet />
      </div>
    </div>
  );
};

const overrideConfig = {
    base: {
        apiUrl: 'https://gate-way.mindx.edu.vn/api-customer-portal/graphql',
    },
    google: {
        ggTagManagerId: 'GTM-PGKD4C8Q',
        ggAnalystId: 'G-N8G4SJH89C'
    },
    hotjar: {
        hotjarId: '3818237',
        hotjarVersion: 6,
    },
    sentry: {
        dns: 'https://3035968bcb0713451391e0bb08a9defc@o4504494963884032.ingest.us.sentry.io/4507050494197760'
    }
};
export default overrideConfig;
// Generate code from clean architecture template

import { StudentEntity } from '../entities';
import { FindByIdPayload } from '../ports/payloads';
import { StudentRepository } from '../ports/repositories';

export class FineOneUseCase {
  constructor(private readonly repo: StudentRepository) {}

  async run(payload?: FindByIdPayload): Promise<StudentEntity> {
    const result = await this.repo.findOne(payload);

    return result;
  }
}

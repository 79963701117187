import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { config } from './config';
import commonEn from './static/locales/en/common.json';
import commonVi from './static/locales/vi/common.json';
import homeEn from './static/locales/en/home.json';
import homeVi from './static/locales/vi/home.json';
import profileEn from './static/locales/en/profile.json';
import profileVi from './static/locales/vi/profile.json';
import learningProgressEn from './static/locales/en/learning-progress.json';
import learningProgressVi from './static/locales/vi/learning-progress.json';

const resources = {
  en: {
    common: commonEn,
    home: homeEn,
    profile: profileEn,
    learningProgress: learningProgressEn,
  },
  vi: {
    common: commonVi,
    home: homeVi,
    profile: profileVi,
    learningProgress: learningProgressVi,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang') || config.i18n.defaultLang,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;

import React, { useState } from 'react';
import { Typography } from '@mx/ui';
import { UseControllerProps, useController } from 'react-hook-form';
import { LoginDto } from '../../adapters/dtos';
import EyeFilledIcon from '@/static/icons/EyeFilled.svg';
import EyeInvisibleFilled from '@/static/icons/EyeInvisibleFilled.svg';

type Props = {
  error: string | undefined;
  placeholder?: string;
};

export const InputPassword = (props: UseControllerProps<LoginDto> & Props): JSX.Element => {
  const { error, placeholder, ...rest } = props;
  const { field } = useController(rest);
  const [isTypePassword, setIsTypePassword] = useState<boolean>(true);
  const isError = Boolean(error);

  const toggleInputType = () => {
    setIsTypePassword((pre) => !pre);
  };

  return (
    <div className="flex flex-col mt-2">
      <div className="relative  w-full">
        <input
          {...field}
          placeholder={placeholder}
          type={isTypePassword ? 'password' : 'text'}
          className={`
            py-1.5
            md:py-3
            px-2
            w-full
            text-base
            border
            box-border
            rounded-lg
            border-solid
            ${isError ? 'border-red-600' : 'border-gray-600'}
          `}
        />
        <div
          className="absolute inset-y-0 right-2 flex items-center cursor-pointer"
          onClick={toggleInputType}
          aria-hidden="true"
        >
          <img src={isTypePassword ? EyeFilledIcon : EyeInvisibleFilled} alt="" />
        </div>
      </div>
      {Boolean(error) && (
        <Typography
          fontTypo="body-xs-desktop"
          weight="regular"
          content={error}
          className="text-mx-red-600 mt-2 text-right"
        />
      )}
    </div>
  );
};

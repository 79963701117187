import React from 'react';
import { LoginTitle, LoginForm, CopyRight } from '../../components';
import { useAppContext } from '@/common/hooks';

export const AuthLoginDesktop: React.FC<unknown> = () => {
  const { t } = useAppContext();
  return (
    <div
      className="
        flex
        w-full
        p-2
        lg:p-0
        lg:w-10/12
        h-[80vh]
        mt-[80px]
        rounded-2xl
        overflow-hidden
        shadow-[0_4px_8px_0_rgba(0,0,0,0.15)]
      "
    >
      <div
        className="
          grow
          flex
          p-14
          pb-24
          pt-40
          items-center
          justify-between
          flex-col
          bg-cover
          bg-no-repeat
          bg-[url('/src/static/image/auth/bg-login-cover.png')]
        "
      >
        <LoginTitle t={t} />
        <CopyRight t={t} />
      </div>
      <LoginForm t={t} />
    </div>
  );
};

import { gql } from '@apollo/client';
import { StudentData } from './student-data';

export const GET_STUDENT_QUERY = gql`
  query Student_get_profile {
    Student_get_profile {
      ${StudentData}
    }
  }
`;

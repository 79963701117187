/* eslint-disable @typescript-eslint/no-explicit-any */
export const pushDataLayer = (event: unknown): void => {
  if (typeof window !== 'object') {
    return;
  }

  if (typeof (window as any).dataLayer === 'undefined') {
    (window as any).dataLayer = [];
  }

  (window as any).dataLayer.push(event);
};

// eslint-disable-next-line no-shadow
export enum StudentStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ONHOLD = 'ONHOLD',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ONHOLD_EXPIRED = 'ONHOLD_EXPIRED',
  DROPOUT = 'DROPOUT',
  WAITING = 'WAITING',
  COMPLETED = 'COMPLETED',
}

import React, { useEffect, useState } from 'react';
// import { getPermission } from '@/modules/accounts/viewmodels/getPermission';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getAccessToken } from '../storages';
import { AppData } from './PageContext';
import i18n from '@/i18n';
import { config } from '@/config';
import { useStudentViewModel } from '@/modules/home/viewmodels/student';
import { StudentEntity } from '@/domains/student/entities';

interface Data {
  // permission: string[];
  t: TFunction;
  changeLanguage(lng: string): Promise<TFunction>;
  currentLanguage: string;
  currentStudent: StudentEntity | null;
  authenticated: boolean;
  setAuthenticated(value: boolean): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppProvider = (props: any) => {
  // const { actionGetPermission, permissionData } = getPermission();
  const [authenticated, setAuthenticated] = useState<boolean>(Boolean(getAccessToken()));

  const { student, actionGetCurrentStudent } = useStudentViewModel();
  const { t } = useTranslation();
  const { language, changeLanguage } = i18n;

  useEffect(() => {
    if (authenticated) {
      actionGetCurrentStudent();
    }
  }, [authenticated]);

  const data: Data = {
    // permission: permissionData,
    t,
    changeLanguage,
    currentLanguage: language || config.i18n.defaultLang,
    authenticated,
    setAuthenticated,
    currentStudent: student,
  };

  // eslint-disable-next-line react/destructuring-assignment
  return <AppData {...data}>{props.children}</AppData>;
};

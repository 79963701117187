import { GET_STUDENT_QUERY } from '../../graphql';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { StudentRepository } from '@/domains/student/ports/repositories';
import { LearningProgressEntity, StudentEntity } from '@/domains/student/entities';

export class StudentRepoImpl implements StudentRepository {
  async findOne(): Promise<StudentEntity> {
    const response: StudentEntity = await HttpClientAdapter.query<StudentEntity>({
      query: GET_STUDENT_QUERY,
      dataKey: 'Student_get_profile',
    });

    return response;
  }

  async getLearningProgress(): Promise<LearningProgressEntity[]> {
    throw new Error('Not implemented');
  }

  async getLearningProgressDetail(): Promise<LearningProgressEntity | undefined> {
    throw new Error('Not implemented');
  }
}

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type QueryParamsType = {
  [propKey: string]: string;
};

export const useSearchQueryParams = (searchParams?: string): QueryParamsType => {
  const { search } = useLocation();

  const queryStringToObject = (params: string): QueryParamsType => {
    const queryParamsObject = new URLSearchParams(params);
    return Object.fromEntries(queryParamsObject.entries());
  };

  const queryParams = useMemo(
    () => queryStringToObject(searchParams || search),
    [searchParams, search],
  );

  return queryParams;
};

import React, { LegacyRef } from 'react';
import ReactSlickSlider, {
  CustomArrowProps,
  ResponsiveObject,
  InnerSlider,
  Settings,
  LazyLoadTypes,
} from 'react-slick';

type SliderSettingProps = Settings;

const Slider = React.forwardRef((props: SliderSettingProps, ref: LegacyRef<ReactSlickSlider>) => {
  const { children, ...restProps } = props;

  return (
    <ReactSlickSlider {...restProps} ref={ref}>
      {children}
    </ReactSlickSlider>
  );
});

export {
  Slider,
  ReactSlickSlider,
  type SliderSettingProps,
  type CustomArrowProps,
  type ResponsiveObject,
  type InnerSlider,
  type LazyLoadTypes,
};

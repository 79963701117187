// Generate code from clean architecture template

import { AuthUserEntity } from '../entities';
import { LoginPayload } from '../ports/payloads';
import { AuthUserRepository } from '../ports/repositories';

export class LoginUseCase {
  constructor(private readonly repo: AuthUserRepository) {}

  async run(payload: LoginPayload): Promise<AuthUserEntity> {
    const result = await this.repo.login(payload);

    return result;
  }
}

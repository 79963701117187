import React from 'react';
import { TFunction } from 'i18next';
import logo from '@/static/image/logo.svg';

type Props = {
  t: TFunction;
};

export const LoginFormHeader = ({ t }: Props): JSX.Element => {
  return (
    <div className="flex items-center flex-col gap-3 pb-16">
      <div
        className="
          flex
          items-center
          bg-white
          w-[114px]
          h-[114px]
          md:w-[130px]
          md:h-[130px]
          border
          shadow-[0_2px_4px_rgba(0,0,0,0.15)]
          rounded-full
        "
      >
        <img src={logo} alt="logo" className="w-full" />
      </div>
      <span className="text-sm md:text-2xl">
        {t('common:loginFormGreeting')} &nbsp;
        <span className="font-semibold text-red-600">{t('common:loginFormGreetingTo')}</span>
      </span>
    </div>
  );
};

import React from 'react';
import { Loading, LoadingProps } from '../Loading';

export const ScopeLoading: React.FC<LoadingProps> = (props: LoadingProps): JSX.Element => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black/[.15]">
      <Loading {...props} />
    </div>
  );
};

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {
  getAccessToken,
  getCurrentStudentId,
  removeAccessToken,
  setCurrentStudentId,
} from '@/common/storages';
import { useSearchQueryParams } from '@/common';
import { PUBLIC_PATHS } from '@/routes';

export const ProtectedRoutes = () => {
  const token = getAccessToken();
  const previousStudentId = getCurrentStudentId();
  const { studentId } = useSearchQueryParams();

  if (!studentId && !previousStudentId) {
    return <Navigate to="/error" />;
  }

  const isDifferentStudent = studentId && studentId !== previousStudentId;

  if (isDifferentStudent || !token) {
    if (studentId) {
      setCurrentStudentId(studentId);
    }
    removeAccessToken();
    return <Navigate to={`${PUBLIC_PATHS.login}?studentId=${studentId || previousStudentId}`} />;
  }

  return <Outlet />;
};

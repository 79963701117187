import { gql } from '@apollo/client';
import { AuthUserData } from './auth-login-data';

export const AUTH_LOGIN = gql`
  query ($payload: StudentLoginPayload!) {
    Student_login(payload: $payload) {
      ${AuthUserData}
    }
  }
`;

import React from 'react';
import { Loading, LoadingProps } from '../Loading';

export const GlobalLoading: React.FC<LoadingProps> = (props: LoadingProps): JSX.Element => {
  return (
    <div className="fixed  inset-0 top-20 flex items-center justify-center bg-black/[.15]">
      <Loading className="w-14 h-14" {...props} />
    </div>
  );
};

import React from 'react';
import { Typography } from '@mx/ui';
import { TFunction } from 'i18next';
import logo from '@/static/icons/logo-text-white.svg';

type Props = {
  t: TFunction;
};

export const CopyRight: React.FC<Props> = ({ t }: Props): JSX.Element => {
  return (
    <div className="flex gap-4 items-center text-white">
      <Typography
        content={t('common:copyright')}
        fontTypo=""
        className="w-28 md:w-36 text-xs md:text-base text-right"
      />
      <img src={logo} alt="logo" className="w-20 sm:w-28" />
    </div>
  );
};

import { defaultsDeep } from 'lodash';
import overrideConfig from './override.config';

export interface Config {
  title: string;
  footer: {
    text: string;
  };
  google: {
    ggTagManagerId: string;
    ggAnalystId: string;
  };
  hotjar: {
    hotjarId: string;
    hotjarVersion: number;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  base: {
    webUrl: string;
    apiUrl: string;
  };
  i18n: {
    vi: string;
    en: string;
    defaultLang: string;
  };
  jwtAuth: {
    storageAccessTokenName: string;
    storageStudentIDName: string;
  };

  theme: {
    breakpoints: {
      sm: number;
      md: number;
      lg: number;
      xl: number;
      xxl: number;
    };
  };

  contacts: {
    complainGeneral: string;
    adultCourse: string;
    kidCourseHN: string;
    kidCourseHCM: string;
    kidCourseOther: string;
    email: string;
  };
  taxCode: string;
  socialMediaChannels: {
    faceBook?: string;
    youtube?: string;
    linkedIn?: string;
    instagram?: string;
    tiktok?: string;
  };
  sentry: {
    dns: string;
  };
}

const defaultConfig: Config = {
  title: import.meta.env.VITE_APP_TITLE || 'MindX Technology School',
  footer: {
    text: import.meta.env.VITE_APP_FOOTER_TEXT || 'MindX Technology School 2022',
  },
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY || 'AIzaSyB-x8gFuVKzbIoB1aYKbG1jrvm8mbZUmkQ',
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || 'mindx-edu-dev.firebaseapp.com',
    databaseURL:
      import.meta.env.VITE_FIREBASE_DATABASE_URL || 'https://mindx-edu-dev.firebaseio.com',
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || 'mindx-edu-dev',
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || 'mindx-edu-dev.appspot.com',
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID || '592744290445',
    appId: import.meta.env.VITE_FIREBASE_APP_ID || '1:592744290445:web:aa82213d363f9b59c5eac4',
    measurementId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID || 'G-QPEELWB8Q4',
  },
  base: {
    webUrl: import.meta.env.VITE_BASE_WEB_URL || 'https://base-dev.mindx.edu.vn',
    apiUrl: import.meta.env.VITE_BASE_API_URL || 'localhost:4001/graphql',
  },
  google: {
    ggTagManagerId: import.meta.env.VITE_GG_TAG_MANAGER_ID || 'GTM-5ZC2VJ37',
    ggAnalystId: import.meta.env.VITE_GG_ANALYST_ID || 'G-0BTK60NS67',
  },
  hotjar: {
    hotjarId: import.meta.env.VITE_HOTJAR_ID || '3818237',
    hotjarVersion: import.meta.env.VITE_HOTJAR_VERSION || 6,
  },
  i18n: {
    vi: 'vi',
    en: 'en',
    defaultLang: 'vi',
  },
  jwtAuth: {
    storageAccessTokenName: 'accessToken',
    storageStudentIDName: 'studentId',
  },
  theme: {
    breakpoints: {
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
    },
  },
  contacts: {
    complainGeneral: '024.771.0666',
    adultCourse: '0247.770.5666',
    kidCourseHN: '0247.771.7888',
    kidCourseHCM: '0287.771.7789',
    kidCourseOther: '0247.773.1666',
    email: 'mindxforbusiness@gmail.com',
  },
  taxCode: '0110021468',
  socialMediaChannels: {
    faceBook: 'https://www.facebook.com/MindX.School',
    youtube: 'https://www.youtube.com/channel/UCkuxJcFwR-iFgThUPKwM_JA/featured',
  },
  sentry: {
    dns: import.meta.env.VITE_SENTRY_DNS || '',
  },
};

export const config = defaultsDeep(overrideConfig, defaultConfig) as Config;

import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthLoginDesktop } from './Desktop';
import { AuthLoginMobile } from './Mobile';
import { useBrowserDevice } from '@/common/hooks/useBrowserDevice';
import {
  getAccessToken,
  getCurrentStudentId,
  removeAccessToken,
  setCurrentStudentId,
} from '@/common/storages';
import { PROTECTED_PATHS } from '@/routes';
import { useSearchQueryParams } from '@/common';

export const AuthLogin: React.FC<unknown> = () => {
  const { isMobile } = useBrowserDevice();
  const currentStudentId = getCurrentStudentId();
  const { studentId } = useSearchQueryParams();
  const token = getAccessToken();

  if (!currentStudentId && !studentId) {
    return <Navigate to="/error" />;
  }

  const isDifferentStudent = Boolean(studentId) && studentId !== currentStudentId;

  if (!isDifferentStudent && token) {
    return <Navigate to={PROTECTED_PATHS.home} />;
  }

  if (isDifferentStudent) {
    setCurrentStudentId(studentId);
    removeAccessToken();
  }

  return (
    <div className="flex w-full items-center justify-center min-h-screen">
      {isMobile ? <AuthLoginMobile /> : <AuthLoginDesktop />}
    </div>
  );
};

import React from 'react';
import { Typography } from '@mx/ui';
import { TFunction } from 'i18next';

type Props = {
  t: TFunction;
};

export const LoginTitle: React.FC<Props> = ({ t }: Props): JSX.Element => {
  return (
    <Typography
      content={t('common:loginFormTitle')}
      weight="semibold"
      fontTypo=""
      className="text-2xl md:text-4xl lg:text-5xl uppercase text-white"
    />
  );
};

import React, { useState } from 'react';
import { GoPerson } from 'react-icons/go';
import { twMerge } from 'tailwind-merge';
import avatarDefault from '@/static/image/avatar-default.png';

type Props = {
  src?: string;
  className?: string;
  shape?: 'square' | 'circle';
  size?: 'small' | 'medium' | 'large';
};

const getClassSize = (size?: 'small' | 'medium' | 'large'): string => {
  let classSize = '';
  switch (size) {
    case 'small':
      classSize = 'w-10 h-10';
      break;
    case 'large':
      classSize = 'w-28 h-28';
      break;
    default:
      classSize = 'w-20 h-20';
  }

  return classSize;
};

const getClassShape = (shape?: 'square' | 'circle'): string => {
  let classShape = '';
  switch (shape) {
    case 'circle':
      classShape = 'rounded-full';
      break;
    default:
      classShape = 'rounded';
  }

  return classShape;
};

export const Avatar: React.FC<Props> = (props: Props): JSX.Element => {
  const { src, className, shape, size } = props;
  const [error, setError] = useState<boolean>(false);

  const shapeClassName = getClassShape(shape);
  const sizeClassName = getClassSize(size);

  const handleImgError = () => {
    setError(true);
  };

  const classes = twMerge(`
    box-border
    overflow-hidden
    shadow-md
    text-center
    bg-white
    ${sizeClassName}
    ${shapeClassName}
    ${className}
  `);

  const imageSrc = src && !error ? src : avatarDefault;

  return (
    <div className={classes}>
      <img
        src={imageSrc}
        alt="avatar"
        className="object-cover w-full h-full"
        onError={handleImgError}
      />
    </div>
  );
};

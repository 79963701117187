/* eslint-disable react/react-in-jsx-scope */
import { useAppContext } from '@/common';
import { getCurrentStudentId } from '@/common/storages';
import { ScopeLoading } from '@/components';
import { useDataLayerAction } from '@/core/context/TagContext';
import { PROTECTED_PATHS } from '@/routes';
import { Button } from '@mx/ui';
import { TFunction } from 'i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthValidation } from '../../validations';
import { useLoginForm } from '../../viewmodels';
import { LoginFormHeader } from './FormHeader';
import { InputPassword } from './InputPassword';

type Props = {
  t: TFunction;
};

export const LoginForm = ({ t }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { setAuthenticated } = useAppContext();
  const dataLayerAction = useDataLayerAction();

  const onSubmitSuccess = (): void => {
    setAuthenticated(true);
    navigate(PROTECTED_PATHS.home);
  };

  const schema = AuthValidation.loginValidationSchema(t);
  const {
    methodForm,
    onSubmit,
    loading: submitting,
    error: loginError,
  } = useLoginForm({
    defaultValue: {
      id: getCurrentStudentId() || '',
      password: '',
    },
    schema,
    onSubmitSuccess,
  });

  const {
    control,
    formState: { errors },
    register,
  } = methodForm;

  const invalidPwdMsg = errors.password?.message ? t(`common:${errors.password.message}`) : '';
  const wrongPasswordMsg = loginError ? t('common:wrongPasswordMsg') : '';

  const { ref, ...inputPwdProps } = register('password');

  useEffect(() => {
    setAuthenticated(false);

    // send event start Login to GA4
    dataLayerAction({
      event: 'ACCESS_LOGIN_PAGE',
      data: {
        user_id: getCurrentStudentId() || '',
      },
    });
  }, []);

  return (
    <div
      className="
        flex
        w-full
        bg-cover
        bg-no-repeat
        p-8
        rounded-2xl
        md:rounded-none
        min-w-[100%]
        md:min-w-[450px]
        md:w-[37%]
        items-center
        justify-center
        box-border
        relative
        bg-[url('/src/static/image/auth/bg-login-form.png')]
      "
    >
      <form onSubmit={onSubmit} className="w-full">
        <LoginFormHeader t={t} />
        <InputPassword
          {...inputPwdProps}
          control={control}
          error={invalidPwdMsg || wrongPasswordMsg}
          placeholder={t('common:passwordPlaceholder') || ''}
        />
        {!invalidPwdMsg && !loginError && (
          <span className="block text-right mt-2 leading-3">
            <small className="text-xs">{t('common:loginPasswordDes')}</small>
          </span>
        )}
        <div className="mt-10 flex justify-center">
          <Button
            htmlType="submit"
            type="filled-danger"
            size="large"
            content={t('common:loginBtn')}
            className="
              font-semibold
              text-xl
              border-0
              min-w-[150px]
              cursor-pointer
              shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]
            "
          />
        </div>
      </form>
      {submitting && <ScopeLoading />}
    </div>
  );
};

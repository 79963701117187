import { accessLoginPage } from './accessLoginPage';
import { pushLearningProgress } from './learningProgress';
import { pushLearningProgressDetail } from './learningProgressDetail';
import { loginSuccess } from './loginSuccess';
import { pushLoginSystem } from './loginSystem';
import { pushStudentProfile } from './studentProfile';
import { pushUserAction } from './userAction';

export type GTMEvent = {
  event: string;
  data?: Record<string, unknown>;
  _clear?: boolean;
};

export const GTMEvents = {
  STUDENT_PROFILE: pushStudentProfile,
  USER_ACTION: pushUserAction,
  LOGIN_SYSTEM: pushLoginSystem,
  LEARNING_PROGRESS: pushLearningProgress,
  LEARNING_PROGRESS_DETAIL: pushLearningProgressDetail,
  ACCESS_LOGIN_PAGE: accessLoginPage,
  LOGIN_SUCCESS: loginSuccess,
};

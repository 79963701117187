export const StudentData = `
  id
  fullName
  status
  imageUrl
  phoneNumber
  email
  gender
  dob
  address
  facebook
  zalo
  school
  job
  needs {
    name
  }
  crmContactId
  customer {
    id
    fullName
    dob
    gender
    phoneNumber
    email
    address
    facebook
    zalo
    school
    job
    needs {
      name
    }
  }
  studyClasses
  dropouts {
    type
    lastClass
    description
    date
    proofPhotos
    studyPackageVersions {
      selectableCourses
      name
      price
      type
      category
      code
    }
  }
  candidateTypes
`;

import React from 'react';
import { LoginTitle, LoginForm, CopyRight } from '../../components';
import { useAppContext } from '@/common/hooks';
import { useWindowSize } from '@/common/hooks/useWindowSize';

export const AuthLoginMobile: React.FC<unknown> = () => {
  const { t } = useAppContext();
  const { height } = useWindowSize();
  const paddingTopClass = height < 700 ? 'pt-16' : 'pt-[134px]';

  return (
    <div
      className={`
          flex
          flex-col
          items-center
          justify-between
          box-border
          w-full
          px-4
          pb-10
          gap-8
          h-screen
          bg-cover
          bg-no-repeat
          bg-[url('/src/static/image/auth/mobile-bg-login-cover.png')]
          ${paddingTopClass}
        `}
    >
      <div
        className="
          flex
          flex-col
          items-center
          gap-8
          w-full
        "
      >
        <LoginTitle t={t} />
        <LoginForm t={t} />
      </div>
      <CopyRight t={t} />
    </div>
  );
};

import React from 'react';
import { TFunction } from 'i18next';
import {
  IoStopwatch,
  IoPauseCircle,
  IoStopCircle,
  IoAlertCircle,
  IoCheckmarkCircle,
  IoPauseCircleOutline,
  IoCheckmarkDoneCircleOutline,
} from 'react-icons/io5';
import { StudentStatusEnum } from '@/modules/student-profile/ interfaces';

type Props = {
  value: string;
  t: TFunction;
};

export const StudentStatus: React.FC<Props> = ({ value, t }: Props): JSX.Element => {
  const renderStatus = (status: string): JSX.Element => {
    switch (status) {
      case StudentStatusEnum.ACTIVE:
        return (
          <div className="flex items-center gap-1 text-green-600 text-xs sm:text-lg font-normal md:font-semibold">
            {t('common:studentStatusActive')}
            <IoCheckmarkCircle />
          </div>
        );
      case StudentStatusEnum.ONHOLD:
        return (
          <div className="flex items-center gap-1 text-blue-600 text-xs sm:text-lg font-normal md:font-semibold">
            {t('common:studentStatusOnhold')}
            <IoStopwatch />
          </div>
        );
      case StudentStatusEnum.ONHOLD_EXPIRED:
        return (
          <div className="flex items-center gap-1 text-yellow-600 text-xs sm:text-lg font-normal md:font-semibold">
            {t('common:studentStatusOnholdExpired')}
            <IoAlertCircle />
          </div>
        );
      case StudentStatusEnum.DROPOUT:
        return (
          <div className="flex items-center gap-1 text-red-500 text-xs sm:text-lg font-normal md:font-semibold">
            {t('common:studentStatusDropout')}
            <IoPauseCircle />
          </div>
        );
      case StudentStatusEnum.WAITING:
        return (
          <div className="flex items-center gap-1 text-blue-500 text-xs sm:text-lg font-normal md:font-semibold">
            {t('common:studentStatusWaiting')}
            <IoPauseCircleOutline />
          </div>
        );
      case StudentStatusEnum.COMPLETED:
        return (
          <div className="flex items-center gap-1 text-green-600 text-xs sm:text-lg font-normal md:font-semibold">
            {t('common:studentStatusCompleted')}
            <IoCheckmarkDoneCircleOutline />
          </div>
        );
      default:
        return (
          <div className="flex items-center gap-1 text-gray-500 text-xs sm:text-lg font-normal md:font-semibold">
            {t('common:studentStatusInactive')}
            <IoStopCircle />
          </div>
        );
    }
  };

  return <>{renderStatus(value)}</>;
};

import React from 'react';
import { useAppContext } from '@/common/hooks';

export const ErrorPage: React.FC<unknown> = () => {
  const { t } = useAppContext();
  return (
    <div className="flex w-full items-center justify-center min-h-screen flex-col">
      <div className="text-4xl text-red-600 font-bold uppercase">{t('common:errorLink')}</div>
    </div>
  );
};

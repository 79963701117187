import { DataLayerInput } from '../../context/TagContext';
import { GTMEvent, GTMEvents } from '../GTMEvents';
import { pushDataLayer } from './pushDataLayer';

export const getEventDataConsumer = () => {
  return ({ event, data }: DataLayerInput) => {
    let prepareData: GTMEvent | undefined;

    switch (event) {
      case 'STUDENT_PROFILE':
        console.log('STUDENT_PROFILE:::', data);

        prepareData = GTMEvents.STUDENT_PROFILE(data);
        break;
      case 'USER_ACTION':
        prepareData = GTMEvents.USER_ACTION(data);
        break;
      case 'LOGIN_SYSTEM':
        console.log('LOGIN_SYSTEM:::', data);

        prepareData = GTMEvents.LOGIN_SYSTEM(data);
        break;
      case 'LEARNING_PROGRESS':
        console.log('LEARNING_PROGRESS:::', data);
        prepareData = GTMEvents.LEARNING_PROGRESS(data);
        break;
      case 'LEARNING_PROGRESS_DETAIL':
        console.log('LEARNING_PROGRESS_DETAIL:::', data);
        prepareData = GTMEvents.LEARNING_PROGRESS_DETAIL(data);
        break;
      case 'ACCESS_LOGIN_PAGE':
        console.log('ACCESS_LOGIN_PAGE:::', data);
        prepareData = GTMEvents.ACCESS_LOGIN_PAGE(data);
        break;
      case 'LOGIN_SUCCESS':
        console.log('LOGIN_SUCCESS:::', data);
        prepareData = GTMEvents.LOGIN_SUCCESS(data);
        break;
      default:
        if (data) {
          prepareData = {
            event: '',
            data,
          };
        }
    }

    if (prepareData) {
      pushDataLayer(prepareData);
    }
  };
};

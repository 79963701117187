import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { LoginDto } from '../adapters/dtos';
import { AuthUserRepoImpl } from '../adapters/repositories';
import { useBaseViewModel } from '@/common';
import { setAccessToken } from '@/common/storages';
import { LoginUseCase } from '@/domains/auth/usecases';

type Props = {
  defaultValue: LoginDto;
  schema: yup.SchemaOf<LoginDto>;
  onSubmitSuccess(): void;
};

export const useLoginForm = (props: Props) => {
  const { schema, defaultValue, onSubmitSuccess } = props;
  const loginUC = new LoginUseCase(new AuthUserRepoImpl());

  const { loading, error, setError, setLoading } = useBaseViewModel();

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const onSubmit = methodForm.handleSubmit(async (data: LoginDto) => {
    setError(undefined);
    setLoading(true);
    try {
      const { accessToken } = await loginUC.run(data);
      setAccessToken(accessToken);
      onSubmitSuccess();
    } catch (e) {
      console.log('login error:::: ', e);
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  });

  return {
    error,
    loading,
    onSubmit,
    methodForm,
  };
};

import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDocumentTitle } from './common';
import { AppProvider } from './common/context/AppContext';
import { AuthLogin } from './modules/auth';
import { PUBLIC_PATHS, PROTECTED_PATHS } from './routes';
import './App.css';
import './index.css';
import { useGTM } from './core/gtag-manager/hooks/useGTM';
import { TagContextInit } from './core/context/TagContext';
import { ProtectedRoutes, ErrorPage, MainLayout, BlankLayout, GlobalLoading } from '@/components';

const Home = lazy(() => import('./modules/home'));
const StudentProfile = lazy(() => import('./modules/student-profile'));
const StudyProduct = lazy(() => import('./modules/study-product'));
const StudentCourse = lazy(() => import('./modules/student-course'));
const LearningProgressList = lazy(() => import('./modules/learning-progress/screens/List'));
const LearningProgressDetail = lazy(() => import('./modules/learning-progress/screens/Detail'));

const App: React.FC = () => {
  useDocumentTitle();
  const { gtmHandler, initScript, noScript } = useGTM();
  return (
    <AppProvider>
      <TagContextInit scripts={[initScript, noScript]} handlers={[gtmHandler]}>
        <Suspense fallback={<GlobalLoading />}>
          <Routes>
            <Route element={<BlankLayout />}>
              <Route path={PUBLIC_PATHS.login} element={<AuthLogin />} />
            </Route>
            <Route element={<MainLayout />}>
              <Route element={<ProtectedRoutes />}>
                <Route path={PROTECTED_PATHS.profile} element={<StudentProfile />} />
                <Route path={PROTECTED_PATHS.studyProduct} element={<StudyProduct />} />
                <Route path={PROTECTED_PATHS.studentCourses} element={<StudentCourse />} />
                <Route path={PROTECTED_PATHS.learningProgress} element={<LearningProgressList />} />
                <Route
                  path={PROTECTED_PATHS.learningProgressDetail}
                  element={<LearningProgressDetail />}
                />
                <Route path={PROTECTED_PATHS.home} element={<Home />} />
              </Route>
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </TagContextInit>
    </AppProvider>
  );
};

export default App;

import { TFunction } from 'i18next';
import * as yup from 'yup';
import { LoginDto } from '../adapters/dtos';

export class AuthValidation {
  static loginValidationSchema = (t: TFunction): yup.SchemaOf<LoginDto> =>
    yup.object().shape({
      password: yup.string().required(`${t('passwordRequiredMsg')}`),
      id: yup.string().required(`${t('studentIdRequiredMsg')}`),
    });
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

const PageContext = React.createContext({} as any);
export const AppContext = React.createContext({} as any);

export const PageProvider = (props: any) => {
  return <PageContext.Provider value={props}>{props.children}</PageContext.Provider>;
};

export const AppData = (props: any) => {
  return <AppContext.Provider value={props}>{props.children}</AppContext.Provider>;
};

export default PageContext;

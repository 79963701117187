import { useState, useEffect } from 'react';
import { config } from '@/config';

interface DeviceType {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const useBrowserDevice = (): DeviceType => {
  const { breakpoints } = config.theme;

  const getDevice = (): DeviceType => {
    const windowWidth = Math.min(window.innerWidth, document.documentElement.clientWidth);
    return {
      isMobile: windowWidth < breakpoints.sm,
      isTablet: windowWidth >= breakpoints.sm && windowWidth < breakpoints.lg,
      isDesktop: windowWidth >= breakpoints.lg,
    };
  };

  const [device, setDevice] = useState<DeviceType>(getDevice());

  useEffect(() => {
    const listener = () => setDevice(getDevice());
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  return device;
};
